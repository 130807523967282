body {
    display: flex;
    flex-direction: column;
}

body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    /*background-color:#1e1e2d!important;*/
    background-color:white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px!important;
    font-weight: 400;
    font-family: Inter,Helvetica,sans-serif;
}

.app-blank{
	background-color: white;
	height: 100%;
	
}

.d-flex {
    display: flex!important;
}
 
.flex-root {
    flex: 1
}

.flex-column-auto {
    flex: none
}

.flex-column-fluid {
    flex: 1 0 auto;
}

.flex-row-auto {
    flex: 0 0 auto
}

.flex-row-fluid {
    flex: 1 auto;
    min-width: 0
}

.flex-center {
    justify-content: center;
    align-items: center
}

.flex-start {
    justify-content: start;
    align-items: start
}

.flex-end {
    justify-content: flex-end;
    align-items: flex-end
}

.flex-stack {
    justify-content: space-between;
    align-items: center
}

@media (min-width: 576px) {
    .flex-sm-root {
        flex:1
    }

    .flex-sm-column-auto {
        flex: none
    }

    .flex-sm-column-fluid {
        flex: 1 0 auto
    }

    .flex-sm-row-auto {
        flex: 0 0 auto
    }

    .flex-sm-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-sm-center {
        justify-content: center;
        align-items: center
    }

    .flex-sm-start {
        justify-content: start;
        align-items: start
    }

    .flex-sm-end {
        justify-content: flex-end;
        align-items: flex-end
    }

    .flex-sm-stack {
        justify-content: space-between;
        align-items: center
    }
}

@media (min-width: 768px) {
    .flex-md-root {
        flex:1
    }

    .flex-md-column-auto {
        flex: none
    }

    .flex-md-column-fluid {
        flex: 1 0 auto
    }

    .flex-md-row-auto {
        flex: 0 0 auto
    }

    .flex-md-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-md-center {
        justify-content: center;
        align-items: center
    }

    .flex-md-start {
        justify-content: start;
        align-items: start
    }

    .flex-md-end {
        justify-content: flex-end;
        align-items: flex-end
    }

    .flex-md-stack {
        justify-content: space-between;
        align-items: center
    }
}

@media (min-width: 992px) {
    .flex-lg-root {
        flex:1
    }

    .flex-lg-column-auto {
        flex: none
    }

    .flex-lg-column-fluid {
        flex: 1 0 auto
    }

    .flex-lg-row-auto {
        flex: 0 0 auto
    }

    .flex-lg-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-lg-center {
        justify-content: center;
        align-items: center
    }

    .flex-lg-start {
        justify-content: start;
        align-items: start
    }

    .flex-lg-end {
        justify-content: flex-end;
        align-items: flex-end
    }

    .flex-lg-stack {
        justify-content: space-between;
        align-items: center
    }
}

@media (min-width: 1200px) {
    .flex-xl-root {
        flex:1
    }

    .flex-xl-column-auto {
        flex: none
    }

    .flex-xl-column-fluid {
        flex: 1 0 auto
    }

    .flex-xl-row-auto {
        flex: 0 0 auto
    }

    .flex-xl-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-xl-center {
        justify-content: center;
        align-items: center
    }

    .flex-xl-start {
        justify-content: start;
        align-items: start
    }

    .flex-xl-end {
        justify-content: flex-end;
        align-items: flex-end
    }

    .flex-xl-stack {
        justify-content: space-between;
        align-items: center
    }
}

@media (min-width: 1400px) {
    .flex-xxl-root {
        flex:1
    }

    .flex-xxl-column-auto {
        flex: none
    }

    .flex-xxl-column-fluid {
        flex: 1 0 auto
    }

    .flex-xxl-row-auto {
        flex: 0 0 auto
    }

    .flex-xxl-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-xxl-center {
        justify-content: center;
        align-items: center
    }

    .flex-xxl-start {
        justify-content: start;
        align-items: start
    }

    .flex-xxl-end {
        justify-content: flex-end;
        align-items: flex-end
    }

    .flex-xxl-stack {
        justify-content: space-between;
        align-items: center
    }
}

.flex-equal {
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 0
}

.w-lg-50 {
    width: 50%!important;
}

.w-lg-30 {
    width: 30%!important;
}

.w-lg-70 {
    width: 70%!important;
}

.w-lg-60 {
    width:60%!important;
}

.w-lg-40 {
    width: 40%!important;
}

 .w-lg-500px {
    width: 500px!important;
}

 .w-lg-600px {
    width: 600px!important;
}

 .w-lg-900px {
    width: 900px!important;
}


 .w-lg-300px {
    width: 300px!important;
}

.p-10 {
    padding: 2.5rem!important;
}
 

.px-10 {
    padding-right: 2.5rem!important;
    padding-left: 2.5rem!important;
}

.mx-auto {
    margin-right: auto!important;
    margin-left: auto!important
}

.order-2 {
    order: 2;
}

.order-lg-1 {
    order: 1;
}

@media (max-width: 768px) {
	.order-2 {
    	order: 1!important;
	}
		
	.order-lg-1, .order-lg-2 {
		order: 2!important;
	}
}




.bgi-size-cover {
    background-size: cover;
}

.bgi-position-center {
    background-position: center;
}

.text-start {
    text-align: left!important
}

.text-end {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

.text-decoration-none {
    text-decoration: none!important
}

.text-decoration-underline {
    text-decoration: underline!important
}

.text-decoration-line-through {
    text-decoration: line-through!important
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.text-wrap {
    white-space: normal!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-break {
    word-wrap: break-word!important;
    word-break: break-word!important
}


.mb-11 {
    margin-bottom: 2.75rem!important;
}

#login-button-primary {
  background-color: #38C6F3;
}

#login-label-primary {
  color: #38C6F3 !important;
}

.btn {
    border-radius: 4px !important;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3) !important;
}

.nickelled-button {
    padding-top: 1px;
    padding-bottom: 1px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: #fe812d;
    border-radius: 3px !important;
    color: white;
    margin-left: 5px;
}

  .login .content h3{color:#4db3a5;text-align:center;font-size:28px;font-weight:400!important}
  .login .content h4{color:#555}
  .login .content .hint{color:#999;padding:0;margin:15px 0 7px}
  .login .content .forget-form,.login .content .login-form{padding:0;margin:0}
  .login .content .form-control{background-color:#dde3ec;height:43px;color:#8290a3;border:1px solid #dde3ec}
  .login .content .form-control:active,.login .content .form-control:focus{border:1px solid #c3ccda}
  .login .content .form-control::-moz-placeholder{color:#8290a3;opacity:1}
  .login .content .form-control:-ms-input-placeholder{color:#8290a3}
  .login .content .form-control::-webkit-input-placeholder{color:#8290a3}
  .login .content select.form-control{padding-left:9px;padding-right:9px}
  .login .content .forget-form,.login .content .register-form{display:none}
  .login .content .form-title{font-weight:300;margin-bottom:25px}
  .login .content .form-actions{clear:both;border:0;padding:25px 30px;margin-left:-30px;margin-right:-30px}
  .login-options{margin-bottom:30px;overflow:hidden}.login-options h4{float:left;font-weight:600;font-size:15px;color:#7d91aa!important}
  .login-options .social-icons{float:right;padding-top:3px}
  .login-options .social-icons li a{border-radius:15px!important;-moz-border-radius:15px!important;-webkit-border-radius:15px!important}
  
  .login .content .form-actions .checkbox{margin-left:0;padding-left:0}
  .login .content .forget-form .form-actions{border:0;margin-bottom:0;padding-bottom:20px}
  .login .content .register-form .form-actions{border:0;margin-bottom:0;padding-bottom:0}
  .login .content .form-actions .btn{margin-top:1px;font-weight:600;padding:10px 20px!important}
  .login .content .form-actions .btn-default{font-weight:600;padding:10px 25px!important;color:#6c7a8d;background-color:#fff;border:none}
  .login .content .form-actions .btn-default:hover{background-color:#fafaff;color:#45b6af}
  
  .login .content .forget-password{font-size:14px;float:right;display:inline-block;margin-top:10px}
  
  .login .content .check{color:#8290a3}
  .login .content .rememberme{margin-left:8px;margin-top:10px}
  .login .content .create-account{margin:0 -40px -30px;padding:15px 0 17px;text-align:center;background-color:#6c7a8d;-webkit-border-radius:0 0 7px 7px;-moz-border-radius:0 0 7px 7px;-ms-border-radius:0 0 7px 7px;-o-border-radius:0 0 7px 7px;border-radius:0 0 7px 7px}
  .login .content .create-account>p{margin:0}.login .content .create-account p a{font-weight:600;font-size:14px;color:#c3cedd}
  .login .content .create-account a{display:inline-block;margin-top:5px}
  .login .copyright{text-align:center;margin:0 auto 30px 0;padding:10px;color:#7a8ca5;font-size:13px}
  
/*  @media (max-width:440px){
	  .login .content,.login .logo{margin-top:10px}
	  .login .content{width:280px}.login .content h3{font-size:22px}
	  .forget-password{display:inline-block;margin-top:20px}
	  .login-options .social-icons{float:left;padding-top:3px}
	  .login .checkbox{font-size:13px}
  }*/
  
  .splash-image{
	  width: 600px;
  }
  
  .splash-image-300{
	 width:100%;
  }
  
  #login-logo-image{
	  width: 400px;
  }
  
  .login-form{
	  width:100%;
  }
  
  @media (max-width: 768px) {
	
	
	.d-flex {
    	display: flex!important;
    	flex-direction: column;
 		flex-wrap: wrap;
	}
	 
	.flex-root {
	    flex: 1
	}
	
	.flex-column-auto {
	    flex: none
	}
	
	.flex-column-fluid {
	    flex: 1 0 auto;
	}
	.flex-row {
	    
    	flex-direction: row;
 		flex-wrap: wrap;
	}
	.flex-row-auto {
	    flex: 0 0 auto
	}
	
	.flex-row-fluid {
	    flex: 1 auto;
	    min-width: 0
	}
	
	.flex-center {
	    justify-content: center;
	    align-items: center
	}
	
	.flex-start {
	    justify-content: start;
	    align-items: start
	}
	
	.flex-end {
	    justify-content: flex-end;
	    align-items: flex-end
	}
	
	.flex-stack {
	    justify-content: space-between;
	    align-items: center
	}
	
	
	.w-lg-600px{
		flex: 0 1 auto;
		width: 400px!important;
	}
	
	.w-lg-900px{
		flex: 0 1 auto;
		width: 500px!important;
	}
	
	.w-lg-500px{
		width: 300px!important;
		flex: 0 1 auto;
	}
	
	.splash-image{
	  width: 50%;
  	}
  	
  	 #login-logo-image{
	  width: 50%;
  	 }
   	.splash-image-300{
		  width: 150px;
  	}
	
}

@media only screen and (max-width: 1280px) {
	.w-lg-600px{
		flex: 0 1 auto;
		width: 400px!important;
	}
	
	.w-lg-900px{
		flex: 0 1 auto;
		width: 600px!important;
	}
	
	.w-lg-500px{
		width: 300px!important;
		flex: 0 1 auto;
	}
	
	.splash-image{
	  width: 50%;
  	}
  	
  	 #login-logo-image{
	  width: 50%;
  	 }
   	.splash-image-300{
		  width: 150px;
  	}
}

.help-block-error{
	color: red;
}

